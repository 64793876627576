/* Material Adjustments */
* {
  -webkit-tap-highlight-color: rgba(0,0,0,0); /* make transparent link selection, adjust last value opacity 0 to 1.0 */
  -webkit-tap-highlight-color: transparent;   /* For some Androids */
}


.mdc-drawer {
  border-right-style: none !important;
}

.mdc-drawer__header {
  padding: 0;
}

.mdc-drawer__header > img {
  max-height: 180px;
  width: 195px;
}

@media (max-width: 500px) {
  .mdc-dialog__content {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
}

@media (max-width: 644px) {
  .mdc-list-item {
    padding: 0px 5px 0px 0px;
  }

  .container {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .mdc-typography--headline5 {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
  }

  .mdc-typography--headline5.total {
    font-size: 1.2rem !important;
    line-height: 1.2rem !important;
  }

  .mdc-list, .mdc-typography--headline6 {
    font-size: 0.9rem !important;
    line-height: 1.1rem !important;
  }
}

.mdc-layout-grid {
  padding: 0 !important;
}

.mdc-fab .mdc-fab-shaped--one {
  border-radius: 50% 0 !important;
}

.mdc-button > .mdc-button__label {
  display: flex;
  align-items: center;
}

.mdc-dialog__surface {
  width: 500px;
  max-width: 98%;
}

.mdc-floating-label {
  line-height: 1.2rem !important;
}

.mdc-drawer__title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}
/* End - Material Adjustments */

/* Forms */
.narrow-container {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  box-sizing: border-box;
}

.bottom-submit {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.bottom-submit > .mdc-button, .bottom-submit > div {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.checkout-bar {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.05);
  color: white;
  border-radius: 20px;
  padding: 3px 10px;
  width: 100%;
  max-width: 450px;
  justify-content: space-around;
  align-items: center;
  transition: 0.8s;
}

.checkout-bar > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.checkout-bar div + div {
  border-left: 1px solid lightgray;
}

@media (max-width: 883px) {
  .checkout-bar {
    background-color: rgba(0, 0, 0, 0.6);
    color: white !important;
  }
}

.checkout-smallbar {
  background-color: #262626;
}

.checkout-smallbar > .checkout-bar {
  background-color: #262626;
  border-radius: 0;
  padding: 5px;
}
/* Category Menu Bar */

.checkout > .mdc-card {
  min-height: 50px;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.checkout > .mdc-data-table td.mdc-data-table__cell {
  white-space: initial;
}

.checkout > .mdc-data-table td.mdc-data-table__cell.label {
  color: var(--mdc-theme-secondary);
  min-width: 140px;
  font-weight: bold;
}

.category-menu-bar {
  background-color: var(--mdc-theme-primary);
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
}

.category-menu-bar > .mdc-button {
  height: 64px;
}

.category-menu-bar > .mdc-button.cart {
  background-color: #262626;
  border-radius: 50% 0 0 50%;
}

.category-menu-bar > .mdc-select--focused .mdc-select__dropdown-icon {
  background-color: #262626;
}
/* End - Category Menu Bar */

.category-chunk-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.mdc-button.category-chunk {
  width: 46%;
  max-width: 200px;
  height: 100px;
  font-size: 18px;
  margin: 5px;
}

.mdc-button.category-chunk .mdc-button__label {
  color: white;
  display: flex;
  justify-content: center;
  width: 98%;
}

.mdc-button.category-chunk .mdc-button__label span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.mdc-button.category-chunk.bg-light .mdc-button__label  {
  color: #262626 !important;
}

.mdc-snackbar > .mdc-snackbar__surface.snackbar--error {
  background-color: var(--mdc-theme-error);
}

.mdc-snackbar > .mdc-snackbar__surface.snackbar--warning {
  background-color: var(--mdc-theme-primary);
}

.mdc-snackbar > .mdc-snackbar__surface.snackbar--error .mdc-snackbar__action:not(:disabled) {
  color: white !important;
}

.mdc-data-table {
  max-width: 100%;
  overflow-x: auto;
  box-sizing: content-box;
}

.mdc-data-table .mdc-data-table__cell {
  padding-right: 8px;
  padding-left: 8px;
}

.custom-container {
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0 1rem 0.5rem 1rem;
  box-sizing: border-box;
}

@media all and (max-width: 575px) {
  .custom-container {
    padding: 0 0.5rem 0.2rem 0.5rem;
  }

  .fab-action > .mdc-fab {
    padding: 12px;
  }

  .fab-action > .mdc-fab .mdc-fab__icon {
    margin-left: 0;
    margin-right: 0;
  }

  .fab-action .mdc-fab__label {
    display: none;
  }
}

.mdc-icon-button.whatsapp-link {
  color: #07bc4c;
}

.fab-action {
  position: fixed;
  right: 10px;
  bottom: 10px;
  font-size: 50px;
  z-index: 1;
}
